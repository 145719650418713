import { Component, OnInit } from '@angular/core';

import { PageHeadingService } from '../../services/page-heading.service';
import { FileService } from '../file.service';
import { ProfileService } from '../../profile/profile.service';

@Component({
  selector: 'app-task-folders',
  templateUrl: './task-folders.component.html',
  styleUrls: ['./task-folders.component.css']
})
export class TaskFoldersComponent implements OnInit {

  folders;
  constructor(
    private fileService: FileService,
    private profileService: ProfileService,
    private pageHeadingService: PageHeadingService
  ) { }

  ngOnInit() {
    this.pageHeadingService.setPageHeading("Task All Folders");

    this.fileService.getAllTaskDirs()
      .subscribe((res) => {
        this.folders = res;
        //console.log(res);
      });

  }

}
