//Author :Jibin Sebastian
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { HttpConfigService } from '../services/http-config.service';

import { ProfileService } from '../profile/profile.service';
const httpOptions = {
  headers: new HttpHeaders({
  })
};


@Injectable()
export class FileService{
   constructor(
     private http: HttpClient,
     private httpConfigService: HttpConfigService,
     private profileService: ProfileService
   ) { }

   baseURL = this.httpConfigService.baseURL;

   allDirListAPI = this.baseURL + 'file/dirs/';
   retOneFileAPI = this.baseURL + 'file/getone/';
   delOneFileAPI = this.baseURL + 'file/delone/';

   allTaskDirListAPI = this.baseURL + 'taskfile/dirs/';
   retOneTaskFileAPI = this.baseURL + 'taskfile/getone/';
   delOneTaskFileAPI = this.baseURL + 'taskfile/delone/';

   //configUrl = this.baseURL+'auth/5e312ab2da935';
   //postUrl = this.baseURL+"file";

  getAllDirs() {
    return this.http.get(this.allDirListAPI);
  }
  getAllFilesInDir(selDir){
    return this.http.get(this.allDirListAPI+selDir);
  }

  getOneFilePath(selFileKey){
    return this.retOneFileAPI+selFileKey;
  }
  postNewProjectFile(selectedFile,apiTarget){
    return this.http.post(this.baseURL+apiTarget+'/'+this.profileService.curLoggedUserId,selectedFile,httpOptions);
    //console.log(selectedFile+" is Selected");
  }
  deleteSelFile(selFileKey){
    return this.http.delete(this.delOneFileAPI+selFileKey+'/'+this.profileService.curLoggedUserId);
  }

  //Task File Operations to backend
  getAllTaskDirs() {
    return this.http.get(this.allTaskDirListAPI);
  }
  getAllTaskFilesInDir(selDir){
    return this.http.get(this.allTaskDirListAPI+selDir);
  }

  getOneTaskFilePath(selFileKey){
    return this.retOneTaskFileAPI+selFileKey;
  }
  postNewTaskFile(selectedFile,apiTarget){
    return this.http.post(this.baseURL+apiTarget+'/'+this.profileService.curLoggedUserId,selectedFile,httpOptions);
    //console.log(selectedFile+" is Selected");
  }
  deleteSelTaskFile(selFileKey){
    return this.http.delete(this.delOneTaskFileAPI+selFileKey+'/'+this.profileService.curLoggedUserId);
  }

  returnFileCategory(fileContentType){
    switch(fileContentType){
      case "image/jpeg":
      return "image";
      break;

      case "image/png":
      return "image";
      break;

      case "application/pdf":
      return "pdf";
      break;

      case "application/vnd.ms-excel":
      return "excel";
      break;

      case "application/x-zip-compressed":
      return "archive";
      break;

      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return "word";
      break;

      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      return "powerpoint";
      break;

      case "audio/mpeg":
      return "audio";
      break;

      case "audio/mp3":
      return "audio";
      break;

      default:
      return "others";
    }
  }
}
