import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ProfileService } from '../profile.service';
@Component({
  selector: 'app-auth-user',
  templateUrl: './auth-user.component.html',
  styleUrls: ['./auth-user.component.css']
})
export class AuthUserComponent implements OnInit {
  constructor(private curRoute: ActivatedRoute, private profileService: ProfileService, private router: Router) { }
  authText = 'Authenticating...';
  authIcon = 'fa fa-spinner fa-spin fa-2x';
  userSecret = this.curRoute.snapshot.params['userSecret'];
  navPath;
  ngOnInit(){
    this.profileService.authUserAccess(this.userSecret).
    subscribe((res: any)=>{
      if(res){
        //console.log(res);

          this.profileService.userLoggedIn = true;
          this.profileService.curLoggedUserSecret = this.userSecret;
          this.profileService.curLoggedUserId = res.Id;
          this.profileService.curLoggedUserName = res.first_name+' '+res.last_name;
          localStorage.setItem('_acc',this.profileService.curLoggedUserSecret);
          this.authIcon = 'fa fa-check fa-2x text-success';
          this.authText ="Authentication Success.. \n Please wait for redirection";
          this.navPath=this.curRoute.snapshot.queryParams['path'];
          setTimeout(()=>{
            this.router.navigate([this.navPath]);
          },1500);
      }
      else{
        this.authText ="Authentication Failed :( ";
      }
    },(error: any)=>{
      this.authIcon = 'fa fa-times fa-2x text-danger';
      this.authText ="Authentication Failed :( ";
    });
  }

}
