import { Component, OnInit } from '@angular/core';

import { PageHeadingService } from '../../services/page-heading.service';
import { ProfileService } from '../../profile/profile.service';
@Component({
  selector: 'app-file-dashbord',
  templateUrl: './file-dashbord.component.html',
  styleUrls: ['./file-dashbord.component.css']
})
export class FileDashbordComponent implements OnInit {

  constructor(
    private profileService: ProfileService,
    private pageHeadingService: PageHeadingService
  ) { }

  ngOnInit() {
    this.pageHeadingService.setPageHeading("File Dash Board");
  }

}
