import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpConfigService } from './services/http-config.service';
import { PageHeadingService } from './services/page-heading.service';
import { FileService } from './files/file.service';
import { ProfileService } from './profile/profile.service';


import { CommonNavbarComponent } from './common/common-navbar/common-navbar.component';
import { CommonTitlebarComponent } from './common/common-titlebar/common-titlebar.component';
import { CommonFooterComponent } from './common/common-footer/common-footer.component';
import { CommonBlankspaceComponent } from './common/common-blankspace/common-blankspace.component';
import { CommonBreadcrumbsComponent } from './common/common-breadcrumbs/common-breadcrumbs.component';
import { CommonPagetitleComponent } from './common/common-pagetitle/common-pagetitle.component';
import { PageNotFoundComponent } from './common/page-not-found/page-not-found.component';
import { CommonDashboardComponent } from './common/common-dashboard/common-dashboard.component';

import { NewFileComponent } from './files/new-file/new-file.component';
import { ListFoldersComponent } from './files/list-folders/list-folders.component';
import { FileDashbordComponent } from './files/file-dashbord/file-dashbord.component';
import { OneFolderComponent } from './files/one-folder/one-folder.component';

import { AuthUserComponent } from './profile/auth-user/auth-user.component';
import { LoadingComponent } from './common/loading/loading.component';

import { TaskFoldersComponent } from './files/task-folders/task-folders.component';
import { TaskOneFolderComponent } from './files/task-one-folder/task-one-folder.component';
import { TaskNewFileComponent } from './files/task-new-file/task-new-file.component';

const appRoutes: Routes = [
  { path: '', component: CommonDashboardComponent },
  { path: 'auth/:userSecret', component: AuthUserComponent },
  { path: 'files', component: FileDashbordComponent },
  { path: 'files/folders', component: ListFoldersComponent  },
  { path: 'files/folders/:folder', component: OneFolderComponent  },
  { path: 'files/folders/:folder/new-file', component: NewFileComponent  },
  { path: 'files/task-folders', component: TaskFoldersComponent  },
  { path: 'files/task-folders/:folder', component: TaskOneFolderComponent  },
  { path: 'files/task-folders/:folder/new-file', component: TaskNewFileComponent  },
  { path: 'page-loading', component: LoadingComponent },
  { path: 'not-found', component: PageNotFoundComponent },
  { path: '**', redirectTo: '/not-found' },
];

@NgModule({
  declarations: [
    AppComponent,
    CommonNavbarComponent,
    CommonTitlebarComponent,
    CommonFooterComponent,
    CommonBlankspaceComponent,
    CommonBreadcrumbsComponent,
    CommonPagetitleComponent,
    NewFileComponent,
    ListFoldersComponent,
    PageNotFoundComponent,
    CommonDashboardComponent,
    FileDashbordComponent,
    OneFolderComponent,
    AuthUserComponent,
    LoadingComponent,
    TaskFoldersComponent,
    TaskOneFolderComponent,
    TaskNewFileComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes)
  ],
  providers: [HttpConfigService,FileService,ProfileService,PageHeadingService],
  bootstrap: [AppComponent]
})
export class AppModule { }
