//Author :Jibin Sebastian
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { HttpConfigService } from '../services/http-config.service';

const httpOptions = {
  headers: new HttpHeaders({
  })
};


@Injectable()
export class ProfileService{
   constructor(
     private http: HttpClient,
     private httpConfigService: HttpConfigService,
     private router: Router
   ) { }

   baseURL = this.httpConfigService.baseURL;
   baseERPURL = this.httpConfigService.baseERPURL;

   authUserUrl = this.baseURL + 'auth/';

   curLoggedUserSecret = null;
   curLoggedUserId = null;
   curLoggedUserName = null;

   userLoggedIn =false;


  authUserAccess(userSecret) {
    return this.http.get(this.authUserUrl+userSecret);
  }

  checkAuthStat(){
    var storedUserSecret = localStorage.getItem('_acc');
    if(storedUserSecret){
      return this.http.get(this.authUserUrl+storedUserSecret);
    }
  }

  logOutUser(){
    this.curLoggedUserSecret = null;
    this.curLoggedUserId = null;
    this.curLoggedUserName = null;

    this.userLoggedIn =false;
    localStorage.removeItem('_acc');
    //this.doc.location.href = this.baseERPURL;
  }

  logInUser(){
    //no special authentication inside angular, redirects to ERP login
    this.userLoggedIn = false;
  }

}
