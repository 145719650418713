import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup,FormControl,Validators } from '@angular/forms';

import { PageHeadingService } from '../../services/page-heading.service';
import { ProfileService } from '../../profile/profile.service';
//import { LoggingService } from '../../services/logging.service';
import { FileService } from '../file.service';

@Component({
  selector: 'app-task-new-file',
  templateUrl: './task-new-file.component.html',
  styleUrls: ['./task-new-file.component.css']
})
export class TaskNewFileComponent implements OnInit {

  contents ={};
  selectedFolder = null;
  taskFileForn;
  constructor(
    //private logServ: LoggingService,
    private fileService: FileService,
    private curRoute: ActivatedRoute,
    private profileService: ProfileService,
    private pageHeadingService: PageHeadingService) { }

  selectedFile: File = null;
  fileInputTitle = null;
  fileInputDescription = null;
  fileToUpload = null;
  btnIsDisabled = false;
  dispSuccess = false;
  ngOnInit() {
    //console.log(this.curRoute.snapshot);
    this.selectedFolder=this.curRoute.snapshot.params['folder'];
    this.pageHeadingService.setPageHeading("Upload to - "+this.selectedFolder);

    this.taskFileForn = new FormGroup({
      'fileSelector': new FormControl(null,Validators.required),
      'fileInputTitle': new FormControl(null,Validators.required),
      'fileInputFolder': new FormControl(this.selectedFolder,Validators.required)
    });

  }

  onFileLoad(event){
    this.selectedFile = <File>event.target.files[0];
    // console.log(this.selectedFile);
    // console.log(this.taskFileForn.value);

  }

  uploadFile(){
    //console.log(this.selectedFile);

    this.btnIsDisabled = true;

    const fd = new FormData();
    const target: string = 'taskfile';

    fd.append('fileToUpload',this.selectedFile,this.selectedFile.name);
    fd.append('fileInputTitle',this.taskFileForn.get('fileInputTitle').value);
    fd.append('fileInputFolder',this.selectedFolder);

    this.fileService.postNewTaskFile(fd,target)
      .subscribe((res) => {
          this.taskFileForn.reset();
          this.dispSuccess = true;
          this.selectedFile = null;
          this.btnIsDisabled = false;
      });

    //console.log(this.httpServ.results);
    //this.logServ.logBtnClick(this.contents);
  }

}
