//Author :Jibin Sebastian
import { Injectable } from '@angular/core';

@Injectable()
export class IconService{
   constructor() { }

   retProperIcon(fileContentType){

     switch(fileContentType){
       case "image":
       return "far fa-2x fa-file-image";
       break;

       case "pdf":
       return "far fa-2x fa-file-pdf";
       break;

       case "excel":
       return "far fa-2x fa-file-excel";
       break;

       case "archive":
       return "far fa-2x fa-file-archive";
       break;

       case "word":
       return "far fa-2x fa-file-word";
       break;

       case "powerpoint":
       return "far fa-2x fa-file-powerpoint";
       break;

       case "audio":
       return "far fa-2x fa-file-audio";
       break;

       default:
       return "far fa-2x fa-file-alt";
     }

   }
}
