import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

import { saveAs } from 'file-saver';

import { PageHeadingService } from '../../services/page-heading.service';
import { FileService } from '../file.service';
import { IconService } from '../icon.service';
import { ProfileService } from '../../profile/profile.service';

@Component({
  selector: 'app-one-folder',
  templateUrl: './one-folder.component.html',
  styleUrls: ['./one-folder.component.css'],
  providers: [IconService]
})
export class OneFolderComponent implements OnInit {

  files=[];
  fileUrl;
  fileCateog;
  selectedImageId;
  selectedFileId;
  selectedFileName;
  selectedDocUrl = this.sanitizer.bypassSecurityTrustResourceUrl('page-loading');
  imageIsLoading = true;
  selectedFolder = null;
  newfilePath = null;
  fileDeleted = false;
  docViewerUrl="https://docs.google.com/viewer?url=";
  docViewerUrlTail="&embedded=true";
  constructor(
    private fileService: FileService,
    private iconService: IconService,
    private curRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private profileService: ProfileService,
    private pageHeadingService: PageHeadingService) { }

  ngOnInit() {


    this.selectedFolder=this.curRoute.snapshot.params['folder'];
    this.pageHeadingService.setPageHeading("Folder - "+this.selectedFolder);
    this.newfilePath="/files/folders/"+this.selectedFolder+"/new-file";

    this.fileService.getAllFilesInDir(this.selectedFolder)
      .subscribe((res: any) => {
        this.files = res;
        //console.log(res);
      });
  }

  retMyCorrectIcon(fileContentType){

    this.fileCateog = this.fileService.returnFileCategory(fileContentType);
    return this.iconService.retProperIcon(this.fileCateog);
  }

  isAnImage(fileContentType){
    this.fileCateog = this.fileService.returnFileCategory(fileContentType);
    if(this.fileCateog=='image'){
      return(1);
    }
    else{
      return(0);
    }
  }

  downloadSelectedFIle(selFileKey: String,selFileName: String){
    //console.log(selFileKey+'Selected');
    this.fileUrl=this.fileService.getOneFilePath(selFileKey);
    //console.log(this.fileUrl);
    saveAs(this.fileUrl,selFileName);
  }

  viewImageSelected(selFileKey: String,selFileName: String){
    this.imageIsLoading = true;
    this.selectedImageId=selFileKey;
    this.selectedFileName=selFileName;
    setTimeout(()=>{
      this.imageIsLoading = false;
    },2000)
  }

  viewDocSelected(selFileKey: String,selFileName: String){
    this.imageIsLoading = true;
    this.selectedFileId=selFileKey;
    this.selectedFileName=selFileName;
    this.selectedDocUrl = this.sanitizer.bypassSecurityTrustResourceUrl('page-loading');
    this.selectedDocUrl = this.loadSelectedDoc(selFileKey);
    setTimeout(()=>{
      this.imageIsLoading = false;
    },2000)
  }

  loadSelectedImage(selFileKey: String,selFileName: String){
    //console.log(selFileKey+'Selected');
    if(selFileKey){
      this.fileUrl=this.fileService.getOneFilePath(selFileKey);
      //console.log(this.fileUrl);
      return (this.fileUrl);
    }
    else{
      return this.sanitizer.bypassSecurityTrustResourceUrl('page-loading');
    }

  }
  loadSelectedDoc(selFileKey: String){
    if(selFileKey){
      this.fileUrl=this.fileService.getOneFilePath(selFileKey);
      //console.log(this.fileUrl);
      return (this.sanitizer.bypassSecurityTrustResourceUrl(this.docViewerUrl+this.fileUrl+this.docViewerUrlTail));
      //return (this.fileUrl);
    }
    else{
      return this.sanitizer.bypassSecurityTrustResourceUrl('page-loading');
    }
  }

  deleteSelectedFile(selFileKey: String, fileName: String){
    if(confirm('Are you sure to delete file - '+fileName+' ?')){
      this.fileService.deleteSelFile(selFileKey)
        .subscribe((res) => {
          //this.files = res;
          this.fileDeleted = true;
          //refresh the file contents
          this.fileService.getAllFilesInDir(this.selectedFolder)
            .subscribe((res: any) => {
              this.files = res;
              //console.log(res);
            });
          //console.log(res);
        });
    }

  }

}
