import { Component, OnInit } from '@angular/core';

import { HttpConfigService } from '../../services/http-config.service';
import { ProfileService } from '../../profile/profile.service';
@Component({
  selector: 'app-common-navbar',
  templateUrl: './common-navbar.component.html',
  styleUrls: ['./common-navbar.component.css']
})
export class CommonNavbarComponent implements OnInit {

  systemShortName = 'Livonia Technologies';

  constructor(
    private profileService: ProfileService,
    private httpConfigService: HttpConfigService
  ) { }

  logInLocation = this.httpConfigService.baseERPURL+'Login/login.php';
  logOutLocation = this.httpConfigService.baseERPURL+'Login/logout.php';
  ngOnInit() {
    this.profileService.checkAuthStat().
    subscribe((res: any)=>{
      if(res){
        //console.log(res);
          this.profileService.userLoggedIn = true;
          this.profileService.curLoggedUserSecret = localStorage.getItem('_acc');
          this.profileService.curLoggedUserId = res.Id;
          this.profileService.curLoggedUserName = res.first_name+' '+res.last_name;
      }
      else{
        this.profileService.userLoggedIn = false;
      }
    },(error: any)=>{
      this.profileService.userLoggedIn = false;
    });
  }
}
