import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({
  })
};


@Injectable()
export class HttpConfigService{
   constructor(private http: HttpClient) { }

   //baseURL="http://localhost:3000/nodejsapp/anlon/";
   baseURL="https://filemanager.pro910.com/nodejsapp/anlon/";

   //baseERPURL="http://localhost/Anlon/";
   baseERPURL="https://anlon.pro910.com/Anlon/";


}
